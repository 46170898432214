<template>
  <v-dialog v-model="dialog" persistent>
    <v-card align="center" class="rounded-card">
      <v-container>
        <h3 class="header-title">{{$t("RouteInformation")}}</h3>
        <v-row>
          <v-col class="d-flex justify-center pa-0 ma-0 mt-3">
            <p class="route-title" v-show="RouteCode || routeTitle">[{{RouteCode}}] {{ routeTitle }}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-3 ma-0">
            <p class="name-loc mb-1">{{ orig }}</p>
            <p class="name-location">{{ origin }}</p>
          </v-col>
          <v-col cols="4" class="pa-1 ma-0 mt-4">
            <v-img
              max-width="50px"
              :src="require('@/assets/buy-ticket/bus-vector.svg')"
            >
            </v-img>
          </v-col>
          <v-col cols="4" class="pa-3 ma-0">
            <p class="name-loc mb-1">{{ dest }}</p>
            <p class="name-location">{{ destination }}</p>
          </v-col>
        </v-row>
        <v-row class="text-left">
          <v-col class="" v-for="(route, index) in ToDest" :key="index" cols="12">
            <!-- <span v-if="index == 0" class="body-2 font-weight-bold">{{origin}} - {{destination}}</span>
            <span v-else class="body-2 font-weight-bold">{{destination}} - {{origin}}</span> -->
            <span class="body-2 font-weight-bold">{{route.trip_name}}</span>
            <p class="detail-route text-justify pt-3 mb-0">
              <span v-for="(trip, index) in route.trip_route" :key="index">
                {{ trip }} <span v-if="index!=route.trip_route.length-1" class="px-1">-</span> 
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="expiredNote === 'ExpiredOnSameDay'" class="d-flex justify-center ma-0">
            <p class="detail-route black--text mb-0 font-weight-black">
              {{ $t("ExpiredOnSameDay") }}
            </p>
          </v-col>
          <v-col
            v-else-if="expiredNote === 'ExpiredAfterPurchase'"
            class="d-flex justify-center ma-0"
          >
            <p class="valid-until black--text mb-0 font-weight-black">
              {{ $t("ValidUntil") }}: {{ fareRule.validate_close_date }}
            </p>
          </v-col>
          <!-- <v-col
            v-else-if="expiredNote === 'ExpiredAfterPurchase'"
            class="d-flex justify-center ma-0"
          >
            <p class="detail-route black--text mb-0 font-weight-black">
              {{ $t("ValidFor") }} {{ fareRule.valid_days }} {{ $t("Days") }}
            </p>
          </v-col>
          <v-col
            v-else-if="expiredNote === 'ExpiredAfterUsage'"
            class="d-flex justify-center ma-0"
          >
            <p class="detail-route black--text mb-0 font-weight-black">
              {{ $t("ValidFor") }} {{ fareRule.valid_days }} {{ $t("ExpiredAfterUsage") }}
            </p>
          </v-col> -->
          <v-col
            v-else-if="expiredNote === 'ValidationPeriod' && validate_close_date != ''"
            class="d-flex justify-center ma-0"
          >
            <p class="detail-route black--text mb-0">
              <span class="font-weight-black">{{ $t("ValidFor") }} </span><br/>
              {{ validate_open_date + " " }}
              {{ '(' + fareRule.validate_open_time +') ' }}
              {{ $t("to") + " " + validate_close_date + " " }} 
              {{ fareRule.validate_close_time ? '(' + fareRule.validate_close_time + ') ' : '(23:59:59)' }}
            </p>
          </v-col>
          <v-col
            v-else-if="expiredNote === 'ValidationPeriod' && validate_close_date == ''"
            class="d-flex justify-center ma-0"
          >
            <p class="detail-route black--text mb-0">
              <span class="font-weight-black">{{$t("ValidFrom")}} </span><br/>
              {{ " " + validate_open_date + ", " }}
              {{ " " + fareRule.validate_open_time + " " }}
            </p>
          </v-col>
          <v-col v-else-if="expiredNote === 'Unlimited'" class="d-flex ma-0">
            <p class="detail-route black--text mb-0 font-weight-black"></p>
          </v-col>
          <v-col v-else class="d-flex ma-0">
            <p class="detail-route black--text mb-0 font-weight-black"></p>
          </v-col>
        </v-row>
        <div align="center">
          <v-spacer></v-spacer>
          <!-- <v-btn
            style="text-transform: capitalize;"
            rounded
            width="50px"
            height="30px"
            color="primary"
            @click="clickClose()"
          >
            {{$t("labelOk")}}
          </v-btn> -->
          <ac-button 
            :name="$t('Close')" 
            :on-click-handler="clickClose"
          />
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import AcButton from "@/components/AcButton.vue";
export default {
  name: "RouteInformation",
  components:{AcButton},
  props: {
    routeTitle: {
      /*
        Description message
      */
      type: String,
      // default:"Route 1 Bến Thành - Bến Xe buýt Chợ Lớn"
    },

    orig: {
      /*
        Description message
      */
      type: String,
      // default: "BT",
    },
    dest: {
      /*
        Description message
      */
      type: String,
      // default: "CT",
    },
    origin: {
      /*
        Description message
      */
      type: String,
      // default:"Ben Than"
    },
    destination: {
      /*
        Description message
      */
      type: String,
      // default:"Cho Tho"
    },

    ToOrig: {
      /*
        Description message
      */
      type: Array,
      // default:"Công trường Mê Linh-Thi Sách-Công trường Mê Linh-Tôn Đức Thắng-Hàm Nghi - Phó Đức Chính - Nguyễn Thái Bình - Ký Con - Trần Hưng Đạo-Nguyễn Tri Phương-Trần Phú-Trần Hưng Đạo-Châu Văn Liêm-Hải Thượng Lãn Ông-Trang Tử-Ga Chợ Lớn A"
    },
    ToDest: {
      /*
        Description message
      */
      type: Array,
      // default:"Ga Chợ Lớn A-Lê Quang Sung-Nguyễn Hữu Thận-Phạm Đình Hổ-Tháp Mười-Hải Thượng Lãn Ông-Châu Văn Liêm-Nguyễn Trãi-Nguyễn Tri Phương-Trần Phú-Trần Hưng Đạo -Hàm Nghi-Hồ Tùng Mậu-đường nhánh S2-Tôn Đức Thắng-Hai Bà Trưng-Đông Du-Thi Sách-Công trường Mê Linh"
    },
    RouteCode: {
      type: String,
    },
    expiredNote: {
      type: String,
    },
    validate_close_date: {
      type: String,
    },
    validate_open_date: {
      type: String,
    },
    fareRule: {
      type: Object,
      default: ()=>{
        return {
          max_trip: 0,
          valid_days: 0,
          max_trip_perday: 0,
          selling_open_date: null,
          selling_open_time: null,
          selling_close_date: null,
          selling_close_time: null,
          validate_open_date: null,
          validate_open_time: null,
          expired_after_usage: 0,
          expired_on_same_day: false,
          validate_close_date: null,
          validate_close_time: null
        }
      }
    },
    dialog: {
      /*
        set open dialog
      */
      type: Boolean,
      required: true,
      default: false,
    },
    btnClose: {
      type: Function,
      required: false,
    },
  },
  methods: {
    clickClose() {
      this.$store.commit("moduleUi/ROUTE_INFORMATION", "");
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 15px;
}
.header-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;

  color: #4bb14e;
}
.route-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: -5px;

  color: #000000;
}
.detail-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-top: -40px;
  margin-bottom: -20px;
  color: #000000;
}

.name-loc {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 118.88%;
  /* identical to box height, or 31px */

  text-transform: capitalize;

  color: #489e4b;
}

.name-location {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  text-transform: capitalize;

  color: #4bb14e;
}

.detail-route {
  margin-top: -10px;
  font-size: 10px;
  color: #4bb14e;
  text-align: center;
}

.valid-until {
  font-size: 14px;
  color: #4bb14e;
  text-align: center;
}
</style>