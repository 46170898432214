<template>
  <v-row>
    <!-- Jangan dikasih margin, nanti bisa jadi ga fixed navbar nya -->
    <v-col
      class="pb-1"
      cols="12"
      no-gutters
      v-for="(item, index) in item"
      :key="index"
    >
      <CardBuyTicket
        :route1="item.name"
        :origin="item.origin_loc"
        :destination="item.destination_loc"
        :price="item.convertedPrice"
        :currency-code="item.selectedCurrency"
        :clickInformation="clickRouteInformation"
        :routeInformation="item"
        :orig="item.origin_code"
        :dest="item.destination_code"
        :is_buy="item.is_buy"
        v-model="item.cart_qty"
        @buy="togglebuy($event, index)"
      />
    </v-col>
  </v-row>
</template>

<script>
import CardBuyTicket from "@/components/CardBuyTicketNew.vue";
// import { component } from 'vue/types/umd';

export default {
  props: ["item", "clickRouteInformation","togglebuy","is_buy"],
  components: { CardBuyTicket },
  methods:{
  },
  updated(){
  }
};
</script>

<style scoped>
</style>